<template>
    <div 
        class="wrg_card" 
        @click="openHandler(item.id)">
        <div class="flex items-center truncate justify-between">
            <div class="flex items-center truncate">
                <div class="pr-2">
                    <a-avatar 
                        :size="26" 
                        icon="team" 
                        :key="workgroupLogoPath"
                        :src="workgroupLogoPath" />
                </div>
                <span class="truncate font-medium">
                    {{ item.name }}
                </span>
            </div>
            <div v-if="showTask" class="pl-2 tasks_count">
                {{ item.tasks }}/<span class="green">{{ item.complete_tasks }}</span>
            </div>
        </div>
        <div class="flex items-center justify-between mt-2">
            <CardDeadStart 
                :item="item" 
                :listProject="listProject" />
            <Members 
                :item="item"
                :visibleCount="1" />
        </div>
    </div>
</template>

<script>
import CardDeadStart from './CardDeadStart.vue'
import Members from './Members.vue'
export default {
    components: {
        CardDeadStart,
        Members
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        listProject: {
            type: Boolean,
            default: true
        },
        tableColumnsList: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        showTask() {
            if(this.tableColumnsList?.length) {
                const find = this.tableColumnsList.find(f => f.key === 'tasks')
                return find ? true : false
            } else
                return false
        },
        workgroupLogoPath() {
            return this.item?.workgroup_logo?.path || null
        }
    },
    methods: {
        openHandler(id) {
            if(this.listProject) {
                this.$router.replace({
                    query: { viewProject: id }
                })
            } else {
                this.$router.replace({
                    query: { viewGroup: id }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.wrg_card{
    border-radius: var(--borderRadius);
    background: #f1f2ff;
    padding: 12px;
    cursor: pointer;
    .green{
        color: #87d068;
    }
    &:not(:last-child){
        margin-bottom: 10px;
    }
    .tasks_count{
        font-size: 13px;
        color: #656565;
    }
}
</style>